import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
    return (
        <div>
            <br/>
            <br/>
            <br/>
            <h1>404 Error</h1>
        </div>
    )
}